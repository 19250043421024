import React from 'react';
import Hero from './components/hero';
import Show from './components/show';
import Tours from './components/tours';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Vatsim from './assets/img/vatsim.png';

import { FaCalendarAlt } from 'react-icons/fa';
import { FaPlane } from "react-icons/fa";
import { FaPaintBrush } from "react-icons/fa";
import { RiTeamFill } from "react-icons/ri";
import { PiAirTrafficControlFill } from "react-icons/pi";
import { FaNetworkWired } from "react-icons/fa";

import A300C from './assets/liveries/A300C.jpg';
import A300P from './assets/liveries/A300P.jpg';
import A320 from './assets/liveries/A320.jpg';
import A20N1 from './assets/liveries/A20N1.jpg';
import A310 from './assets/liveries/A310.jpg';
import A321 from './assets/liveries/A321.jpg';
import TBM from './assets/liveries/TBM.jpg';
import A339 from './assets/liveries/A339.jpg';
import A380 from './assets/liveries/A380.jpg';
import B777 from './assets/liveries/B777.jpg';
import Bae from './assets/liveries/Bae.jpg';
import Cessna from './assets/liveries/Cessna.jpg';
import E190 from './assets/liveries/E190.jpg';
import H175 from './assets/liveries/H175.jpg';
import MAX8 from './assets/liveries/MAX8.jpg';
import XA312 from './assets/liveries/XA321.jpg';
import XMD11 from './assets/liveries/XMD11.jpg';
import XA320 from './assets/liveries/XA320.jpg';
import XA350 from './assets/liveries/XA350.jpg';
import XA20N from './assets/liveries/XA20N.jpg';
import XA319 from './assets/liveries/XA319.jpg';
import XQ4XP from './assets/liveries/XQ4XP.jpg';

//Test

const App = () => {

  const handleJoinClick = () => {
    window.location.href = 'https://vamsys.io/login/airfox';
  };

  const handleRegistrationClick = () => {
    window.location.href = 'https://discord.gg/ZHtM8VEwSw';
  };

  return (
    <div className='body'>
      <Hero />
      <Container className='container-content'>
        <Row>
          <Col className='container-custom pt-5'>
            <Row>
              <h1 className='custom-h1 airfox-font-normal'>Willkommen bei Airfox!</h1>

              <Container>
                <ul class="nav nav-pills flex" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link menu-button active" id="details-tab" data-bs-toggle="pill" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link menu-button" id="fleet-msfs-tab" data-bs-toggle="pill" data-bs-target="#fleet-msfs" type="button" role="tab" aria-controls="fleet-msfs" aria-selected="false">Flotte MSFS</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link menu-button" id="fleet-xplane-tab" data-bs-toggle="pill" data-bs-target="#fleet-xplane" type="button" role="tab" aria-controls="fleet-xplane" aria-selected="false">Flotte X-Plane</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link menu-button" id="vamsys-tab" data-bs-toggle="pill" data-bs-target="#vamsys" type="button" role="tab" aria-controls="vamsys" aria-selected="false">Vamsys</button>
                  </li>
                </ul>
              </Container>

              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                  <Row>
                    <Col md={6} className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body>
                          <Card.Title as='h3' className='airfox-font-normal title-with-icon'>
                            Flotte
                            <FaPlane style={{ marginRight: '8px', color: '#FEC748' }} />
                          </Card.Title>
                          <Card.Text className='font-roboto'>
                            Unsere virtuelle Airline bietet dir eine extrem vielseitige Flotte, die Flugzeugmuster aller Art umfasst.
                            Egal, ob du ein bestimmtes Flugzeug fliegen möchtest oder einfach Abwechslung suchst – bei uns bist du genau richtig. <br />
                            <br />
                            Vom kleinen Propellerflugzeug bis zum großen Jumbo-Jet ist alles vertreten.
                            Wir sind immer offen dafür, unsere Flotte zu erweitern, um dir noch mehr Möglichkeiten zu bieten.
                            Deine Wünsche und Vorlieben stehen bei uns im Mittelpunkt.<br />
                            <br />
                            Komm an Bord und finde deinen Traumflieger bei uns! Ob du nun die neuesten Modelle oder bewährte Klassiker bevorzugst – wir haben das passende Flugzeug für dich.
                            Werde Teil unserer Community und genieße die Freiheit, jederzeit das Flugzeug fliegen zu können, das du gerade möchtest.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body>
                          <Card.Title as='h3' className='airfox-font-normal title-with-icon'>
                            Events
                            <FaCalendarAlt style={{ marginRight: '8px', color: '#FEC748' }} />
                          </Card.Title>
                          <Card.Text className='font-roboto'>
                            Unser Event-System bietet dir spannende und herausfordernde Flugerlebnisse!
                            Wir planen spezielle Flüge, die nicht nur deine Fähigkeiten als Pilot auf die Probe stellen, sondern dir auch die Möglichkeit geben, Bonuspunkte zu sammeln.
                            Egal, ob es um schwierige Anflüge, außergewöhnliche Wetterbedingungen oder lange Strecken geht – unsere Events sind so konzipiert,
                            dass sie jede Menge Spaß und Herausforderungen bieten. <br />
                            <br />
                            Mit jedem Event hast du die Chance, deine Flugkünste zu verbessern und gleichzeitig
                            in unserer Rangliste aufzusteigen. Sei dabei, meistere die Herausforderungen und sammle Bonuspunkte, um exklusive Belohnungen zu erhalten.
                            Mach mit und erlebe unvergessliche Abenteuer am Himmel!
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body>
                          <Card.Title as='h3' className='airfox-font-normal title-with-icon'>
                            Liveries
                            <FaPaintBrush style={{ marginRight: '8px', color: '#FEC748' }} />
                          </Card.Title>
                          <Card.Text className='font-roboto'>
                            Unsere virtuelle Airline bietet dir eine beeindruckende Auswahl an individuellen Liveries für viele Flugzeuge. <br />
                            <br />
                            Das Design, Logo und die Farben werden von uns vorgegeben, sodass wir ein einheitliches und professionelles Erscheinungsbild wahren. Wenn du jedoch ein bestimmtes Flugzeugmodell fliegen
                            möchtest, das noch keine Livery hat, sind wir für Wünsche immer offen. <br />
                            <br />
                            Unser Team aus leidenschaftlichen Paintern freut sich darauf, neue
                            Liveries nach deinen Vorstellungen zu erstellen. Teile uns einfach mit, welches Flugzeug du in unseren Farben sehen möchtest, und wir setzen es um.
                            So kannst du dein Lieblingsflugzeug mit unserer unverwechselbaren Livery fliegen und ein Teil unserer einzigartigen Community werden!
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body>
                          <Card.Title as='h3' className='airfox-font-normal title-with-icon'>
                            Team
                            <RiTeamFill style={{ marginRight: '8px', color: '#FEC748' }} />
                          </Card.Title>
                          <Card.Text className='font-roboto'>
                            Unser erfahrenes Team steht dir jederzeit mit Rat und Tat zur Seite.
                            Egal, ob du ein Fluganfänger bist oder ein erfahrener Profi – bei uns findest du immer den richtigen Ansprechpartner. <br />
                            <br />
                            Wir sind stolz darauf, eine Community zu haben, in der jeder Pilot die Unterstützung erhält, die er benötigt.
                            Unsere Experten helfen dir bei allen Fragen und Problemen, sei es bei der Flugplanung, technischen Details oder speziellen Herausforderungen.<br />
                            <br />
                            Mit unserer langjährigen Erfahrung sorgen wir dafür, dass du die Informationen und Tipps bekommst,
                            die dich weiterbringen. Werde Teil unserer Airline und profitiere von einem Team, das deine Leidenschaft für die Fliegerei teilt und dich auf
                            deinem Weg unterstützt!
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} className="d-flex">
                      <Card className="flex-fill">
                        <Card.Body>
                          <Card.Title as='h3' className='airfox-font-normal title-with-icon'>
                            System
                            <PiAirTrafficControlFill style={{ marginRight: '8px', color: '#FEC748' }} />
                          </Card.Title>
                          <Card.Text className='font-roboto'>
                            Wir nutzen das Flightmanagement System "VAMSYS" zur Verwaltung unserer Flüge, um dir ein optimales Erlebnis zu bieten.
                            Mit VAMSYS kannst du Flüge buchen, deine eigene Performance verfolgen und die Reichweite der Routen unserer Airline betrachten.
                            Es bietet eine benutzerfreundliche Oberfläche, die es dir leicht macht, deine Flüge effizient zu planen und durchzuführen. <br />
                            <br />
                            Um das Ganze abzurunden, setzen wir Pegasus-ACARS ein, das mit allen gängigen Flugsimulatoren kompatibel ist.
                            Pegasus-ACARS ermöglicht eine nahtlose Integration und sorgt dafür, dass deine Flugdaten präzise erfasst und ausgewertet werden. <br />
                            <br />
                            Egal, welchen Simulator du verwendest, unsere Systeme bieten dir eine umfassende und intuitive Lösung für dein virtuelles Flugerlebnis.
                            Werde Teil unserer Airline und erlebe, wie einfach und angenehm Flugmanagement sein kann!
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col md={6} className="d-flex">
                      <Card className="flex-fill">
                        <a href="https://vatsim.net/" target="_blank" rel="noopener noreferrer">
                          <Card.Img variant="top" src={Vatsim} className='p-4' />
                        </a>
                        <Card.Body>
                          <Card.Title as='h3' className='airfox-font-normal title-with-icon'>
                            Vatsim
                            <FaNetworkWired style={{ marginRight: '8px', color: '#FEC748' }} />
                          </Card.Title>
                          <Card.Text className='font-roboto'>
                            Wir sind eine Online-VA und fliegen hauptsächlich auf dem Netzwerk VATSIM. <br />
                            <br />
                            Dort sind wir als Airline gelistet und unter dem Callsign AFX (Airfox) bekannt.
                            Unsere Piloten genießen es, auf VATSIM zu fliegen, da Online-Aktivitäten auf dem Netzwerk Bonuspunkte bringen und besonders geschätzt werden.
                            Durch die Interaktion mit anderen Piloten und virtuellen Fluglotsen erlebst du die Flugsimulation auf einem neuen Level.<br />
                            <br />
                            Trotzdem möchten wir auch denjenigen entgegenkommen, die lieber offline fliegen - das ist selbstverständlich auch bei uns möglich.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>

                <div class="tab-pane fade" id="vamsys" role="tabpanel" aria-labelledby="vamsys-tab">
                  <Card>
                    <Card.Body>
                      <iframe src="https://vamsys.co.uk" title="Vamsys" width="100%" height="1850px" frameborder="0"></iframe>
                    </Card.Body>
                  </Card>
                </div>

                <div class="tab-pane fade" id="fleet-msfs" role="tabpanel" aria-labelledby="fleet-msfs-tab">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A300C} />
                            <Card.Body>
                              iniBuilds A300-600R Cargo
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A300P} />
                            <Card.Body>
                              iniBuilds A300-600R Pax
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A320} />
                            <Card.Body>
                              Fenix A320
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A339} />
                            <Card.Body>
                              Headwind A339
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A380} />
                            <Card.Body>
                              FBW A380
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={B777} />
                            <Card.Body>
                              PMDG 777-300
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={Bae} />
                            <Card.Body>
                              Justflight BAE 146-300
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={Cessna} />
                            <Card.Body>
                              Flysimware Cessna 414
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={E190} />
                            <Card.Body>
                              FSS E-190
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={H175} />
                            <Card.Body>
                              Hype H145
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={TBM} />
                            <Card.Body>
                              Asobo Daher TBM 930
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A321} />
                            <Card.Body>
                              LatinVFR A321 Neo
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A20N1} />
                            <Card.Body>
                              iniBuilds A20N
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={A310} />
                            <Card.Body>
                              iniBuilds A310
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={MAX8} />
                            <Card.Body>
                              iFly 737 Max 8
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>

                <div class="tab-pane fade" id="fleet-xplane" role="tabpanel" aria-labelledby="fleet-xplane-tab">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XA312} />
                            <Card.Body>
                              ToLiss A321
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XMD11} />
                            <Card.Body>
                              Rotate MD 11 Cargo
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XA320} />
                            <Card.Body>
                              Flight Factor A320
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XA350} />
                            <Card.Body>
                              Flight Factor A350
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XA20N} />
                            <Card.Body>
                              ToLiss A20N
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XA319} />
                            <Card.Body>
                              ToLiss A319
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <Card.Img variant="top" src={XQ4XP} />
                            <Card.Body>
                              FlyJSim Q4XP - Dash 8
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Row>
          </Col>

          <Col className='p-5 flex-column'>
            <h1 className='custom-h1-blue airfox-font-normal'>Vorstellung</h1>
            <p className='font-color-grey'>
              Die Airfox wurde am 01.12.2019 durch vier Flugsimulator-Enthusiasten ins Leben gerufen.<br />
              Nach langer Mitgliedschaft in einer virtuellen Airline spürten wir den Drang, die eigenen Ideen und Vorstellungen unabhängig umsetzen zu können. Das Hauptaugenmerk der Airfox liegt auf dem Spaß am gemeinsamen Fliegen in einer aktiven und angenehmen Community.<br />
              <br />
              Wir wollen nicht die größte oder beste virtuelle Airline sein, jedoch haben wir das Ziel eine der modernsten virtuellen Airlines zu werden. Eine breit aufgestellte Flotte, Gruppenflüge, Touren, Events und ein aktiv genutzter Discord-Server runden das Erlebnis bei Airfox ab.<br />
              <br />
              Unser eigens programmierter Discord-Bot liefert unseren Piloten alle Infos die sie während eines Fluges benötigen: METARs, NOTAMs, ATC-Infos (der Pilot wird inflight aktiv durch den Bot informiert, insofern ein Lotse in seinem Flugbereich online ist).<br />
              <br />
              Unsere Zielgruppe besteht aus Flugbegeisterten, die Spaß an der Flugsimulation und einer aktiven und lebhaften Community haben. Trifft das auf Dich zu, freuen wir uns auf Dich!
            </p>

            <hr className='custom-hr' />
            <h1 className='custom-h1-blue airfox-font-normal'>Anmeldung</h1>

            <Col>
              <p className='font-color-grey'>
                Du möchtest ein Teil der Airfox werden? Über den Button "Bewerben" gelangst du auf unseren Discord-Server, wo dich unser Staff für ein kleines Bewerbungsgespräch in Empfang nehmen wird.
              </p>

              <Row>
                <Col>
                  <Button variant="primary" size="lg" className="join-button" onClick={handleJoinClick}>Login</Button>
                </Col>
                <Col>
                  <Col>
                    <Button variant="primary" size="lg" className="join-button" onClick={handleRegistrationClick}>Bewerben</Button>
                  </Col>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col>
                  <a href="https://www.patreon.com/airfox_virtual" class="patreon-button join-button">
                    <img src="https://seeklogo.com/images/P/patreon-logo-C0B52F951B-seeklogo.com.png" alt="Patreon Logo" width="24" height="24" />
                    Unterstütze uns auf Patreon
                  </a>
                </Col>
              </Row>
            </Col>

            <hr className='custom-hr' />
            <h1 className='custom-h1-blue airfox-font-normal'>Touren</h1>
            <Row>
              <Tours />
            </Row>

            <hr className='custom-hr' />
            <h1 className='custom-h1-blue airfox-font-normal'>Discord</h1>
            <Row>
              <Col md={6}>
                <iframe src="https://discord.com/widget?id=650661008028991516&theme=dark" title="Discord Widget" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
              </Col>
              <Col md={6}>
                <p className='font-color-grey'>
                  Wir nutzen Discord als zentrale Kommunikationsplattform. Hier findest Du alle Infos zu unseren Flügen, Events und kannst Dich mit anderen Piloten austauschen.<br />
                  <br />
                  Auch außerhalb des Fliegens ist der Discord-Server ein Ort, um sich zu unterhalten und gemeinsame Interessen zu teilen. Darüber hinaus werden auf unserem Discord-Server allerlei Fragen beantwortet und Probleme gelöst, egal ob im Flugzeug, mit dem Simulator, dem PC oder anderweitige.<br />
                  <br />
                  Hin und wieder wird sogar das On-Board-Catering diskutiert.<br />
                  <br />
                  Dabei achten wir auf ein harmonisches Miteinander; Themen der Weltpolitik, Religion o.ä. haben bei uns keinen Platz. Es geht einzig und alleine um die Welt der Flugsimulation im Zusammenhang mit einer freundlichen und hilfsbereiten Community.
                </p>
              </Col>
            </Row>

            <hr className='custom-hr' />
            <h1 className='custom-h1-blue airfox-font-normal'>Screenshots</h1>
            <Row>
              <Show />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
