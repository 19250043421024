import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Image01 from '../assets/tours/a380-europe.png';
import Image02 from '../assets/tours/a380-world.png';
import Image03 from '../assets/tours/europe-city.png';
import Image04 from '../assets/tours/greek-islands.jpg';
import Image05 from '../assets/tours/mediterranean.png';
import Image06 from '../assets/tours/usa-west.jpg';

const Tours = () => {
  return (
    <div className="slideTours-container">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image01}
            alt="Bild 1"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image02}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image03}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image04}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image05}
            alt="Bild 2"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Image06}
            alt="Bild 2"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Tours;
